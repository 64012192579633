// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jiva-js": () => import("./../../src/pages/about-jiva.js" /* webpackChunkName: "component---src-pages-about-jiva-js" */),
  "component---src-pages-about-things-js": () => import("./../../src/pages/about-things.js" /* webpackChunkName: "component---src-pages-about-things-js" */),
  "component---src-pages-aboutme-js": () => import("./../../src/pages/aboutme.js" /* webpackChunkName: "component---src-pages-aboutme-js" */),
  "component---src-pages-articles-designing-mobile-app-for-iot-products-js": () => import("./../../src/pages/articles/designing-mobile-app-for-iot-products.js" /* webpackChunkName: "component---src-pages-articles-designing-mobile-app-for-iot-products-js" */),
  "component---src-pages-articles-service-design-post-its-js": () => import("./../../src/pages/articles/service-design-post-its.js" /* webpackChunkName: "component---src-pages-articles-service-design-post-its-js" */),
  "component---src-pages-articles-service-with-touchpoints-js": () => import("./../../src/pages/articles/service-with-touchpoints.js" /* webpackChunkName: "component---src-pages-articles-service-with-touchpoints-js" */),
  "component---src-pages-articles-tools-for-designing-services-js": () => import("./../../src/pages/articles/tools-for-designing-services.js" /* webpackChunkName: "component---src-pages-articles-tools-for-designing-services-js" */),
  "component---src-pages-how-work-js": () => import("./../../src/pages/how-work.js" /* webpackChunkName: "component---src-pages-how-work-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-thinking-copy-js": () => import("./../../src/pages/my-thinking-copy.js" /* webpackChunkName: "component---src-pages-my-thinking-copy-js" */),
  "component---src-pages-my-thinking-js": () => import("./../../src/pages/my-thinking.js" /* webpackChunkName: "component---src-pages-my-thinking-js" */),
  "component---src-pages-say-hello-js": () => import("./../../src/pages/say-hello.js" /* webpackChunkName: "component---src-pages-say-hello-js" */),
  "component---src-pages-what-offer-js": () => import("./../../src/pages/what-offer.js" /* webpackChunkName: "component---src-pages-what-offer-js" */),
  "component---src-pages-work-js": () => import("./../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-product-design-3-furniture-pieces-js": () => import("./../../src/pages/work/product-design-3-furniture-pieces.js" /* webpackChunkName: "component---src-pages-work-product-design-3-furniture-pieces-js" */),
  "component---src-pages-work-product-design-hop-philips-js": () => import("./../../src/pages/work/product-design-hop-philips.js" /* webpackChunkName: "component---src-pages-work-product-design-hop-philips-js" */),
  "component---src-pages-work-product-design-household-dustbins-js": () => import("./../../src/pages/work/product-design-household-dustbins.js" /* webpackChunkName: "component---src-pages-work-product-design-household-dustbins-js" */),
  "component---src-pages-work-product-design-mumbai-street-furniture-js": () => import("./../../src/pages/work/product-design-mumbai-street-furniture.js" /* webpackChunkName: "component---src-pages-work-product-design-mumbai-street-furniture-js" */),
  "component---src-pages-work-product-design-persono-coffee-table-js": () => import("./../../src/pages/work/product-design-persono-coffee-table.js" /* webpackChunkName: "component---src-pages-work-product-design-persono-coffee-table-js" */),
  "component---src-pages-work-service-design-acrole-js": () => import("./../../src/pages/work/service-design-acrole.js" /* webpackChunkName: "component---src-pages-work-service-design-acrole-js" */),
  "component---src-pages-work-service-design-blueprint-js": () => import("./../../src/pages/work/service-design-blueprint.js" /* webpackChunkName: "component---src-pages-work-service-design-blueprint-js" */),
  "component---src-pages-work-service-design-bsmart-js": () => import("./../../src/pages/work/service-design-bsmart.js" /* webpackChunkName: "component---src-pages-work-service-design-bsmart-js" */),
  "component---src-pages-work-service-design-covidprojects-js": () => import("./../../src/pages/work/service-design-covidprojects.js" /* webpackChunkName: "component---src-pages-work-service-design-covidprojects-js" */),
  "component---src-pages-work-service-design-hive-part-1-js": () => import("./../../src/pages/work/service-design-hive-part-1.js" /* webpackChunkName: "component---src-pages-work-service-design-hive-part-1-js" */),
  "component---src-pages-work-service-design-hive-part-2-js": () => import("./../../src/pages/work/service-design-hive-part-2.js" /* webpackChunkName: "component---src-pages-work-service-design-hive-part-2-js" */),
  "component---src-pages-work-service-design-keycrime-js": () => import("./../../src/pages/work/service-design-keycrime.js" /* webpackChunkName: "component---src-pages-work-service-design-keycrime-js" */),
  "component---src-pages-work-service-design-keycrime-private-js": () => import("./../../src/pages/work/service-design-keycrime-private.js" /* webpackChunkName: "component---src-pages-work-service-design-keycrime-private-js" */),
  "component---src-pages-work-service-design-keycrime-see-25-everything-0389-js": () => import("./../../src/pages/work/service-design-keycrime-see25everything0389.js" /* webpackChunkName: "component---src-pages-work-service-design-keycrime-see-25-everything-0389-js" */),
  "component---src-pages-work-service-design-kin-js": () => import("./../../src/pages/work/service-design-kin.js" /* webpackChunkName: "component---src-pages-work-service-design-kin-js" */),
  "component---src-pages-work-service-design-levico-terme-js": () => import("./../../src/pages/work/service-design-levico-terme.js" /* webpackChunkName: "component---src-pages-work-service-design-levico-terme-js" */),
  "component---src-pages-work-service-design-mercedes-js": () => import("./../../src/pages/work/service-design-mercedes.js" /* webpackChunkName: "component---src-pages-work-service-design-mercedes-js" */),
  "component---src-pages-work-service-design-muvisi-js": () => import("./../../src/pages/work/service-design-muvisi.js" /* webpackChunkName: "component---src-pages-work-service-design-muvisi-js" */),
  "component---src-pages-work-service-design-muvisi-private-js": () => import("./../../src/pages/work/service-design-muvisi-private.js" /* webpackChunkName: "component---src-pages-work-service-design-muvisi-private-js" */),
  "component---src-pages-work-service-design-muvisi-project-03-full-2589-js": () => import("./../../src/pages/work/service-design-muvisi-project03full2589.js" /* webpackChunkName: "component---src-pages-work-service-design-muvisi-project-03-full-2589-js" */),
  "component---src-pages-work-service-design-postop-js": () => import("./../../src/pages/work/service-design-postop.js" /* webpackChunkName: "component---src-pages-work-service-design-postop-js" */),
  "component---src-pages-work-service-design-progression-js": () => import("./../../src/pages/work/service-design-progression.js" /* webpackChunkName: "component---src-pages-work-service-design-progression-js" */),
  "component---src-pages-work-service-design-save-js": () => import("./../../src/pages/work/service-design-save.js" /* webpackChunkName: "component---src-pages-work-service-design-save-js" */),
  "component---src-pages-work-service-design-tech-no-mad-js": () => import("./../../src/pages/work/service-design-tech-no-mad.js" /* webpackChunkName: "component---src-pages-work-service-design-tech-no-mad-js" */),
  "component---src-pages-work-service-design-tecne-js": () => import("./../../src/pages/work/service-design-tecne.js" /* webpackChunkName: "component---src-pages-work-service-design-tecne-js" */),
  "component---src-pages-work-service-design-tecne-private-js": () => import("./../../src/pages/work/service-design-tecne-private.js" /* webpackChunkName: "component---src-pages-work-service-design-tecne-private-js" */)
}

